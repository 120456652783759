.table thead th {
  
    font-weight: 500;
}
.modal-dialog{
    .modal-body{
       .table{
        text-align: left !important;
           tr{
               td{
                 >a{
                    color: #fdb714!important;
                   }  
               }
               
               th{
                 
               }
           }
           
          
    }  
    }
   
}

a{
&:not(.btn):not(.page-link):not(.no-outline):not(.nav-link):not(.custom-select):not(.dropdown-item):not(.badge){
display:inline-block;
margin-top:0.15rem;
    padding: 0.25px 0.425rem;
    font-weight: 400;
    &:hover{
        text-decoration: none;
    }
&:active,&:focus{
outline:1px dashed #7987a1;
    text-decoration: none;
}
}

}

@for $i from 1 through 9 {
    .fw-#{($i * 100)} {
        font-weight: ($i * 100) !important;
    }
}
@for $i from 10 through 50 {
    .fs-#{$i} {
        font-size:#{$i}px !important;
    }

}

